<template>
  <div class="block block-rounded block-fx-pop">
    <div class="block-content block-content-full">
      <DataTable
        :items="allLogs.data"
        :total="allLogs.total"
        :loading="loading.getAllLogs"
        :columns="columns"
        :page-size="20"
        :fetch-items="getAllLogs"
        default-sort-key="createdAt"
        :default-sort-direction="-1"
      >
        <template v-slot:createdAt="slotProps">
          {{ slotProps.data.createdAt | date('DD/MM/YYYY HH:mm:ss') }}
        </template>

        <template v-slot:userSub="slotProps">
          {{ slotProps.data.userSub | user(users.data) }}
        </template>

        <template v-slot:context-type="slotProps">
          <span class="badge badge-primary text-capitalize">{{ slotProps.data.context.type }}</span>
        </template>

        <template v-slot:type="slotProps">
          <span class="badge text-capitalize">{{ handleType(slotProps.data.type) }}</span>
        </template>

        <template v-slot:context="slotProps">
          <div :id="`accordion_${slotProps.data._id}`">
            <div>
              <div :id="`accordion_${slotProps.data._id}_h1`" role="tab">
                <a
                  class="font-w600"
                  data-toggle="collapse"
                  :data-parent="`#accordion_${slotProps.data._id}`"
                  :href="`#accordion_${slotProps.data._id}_q1`"
                  >More Details...</a
                >
              </div>
              <div :id="`accordion_${slotProps.data._id}_q1`" class="collapse pt-3" :data-parent="`#accordion_${slotProps.data._id}`">
                <div v-if="slotProps.data.context.idsDeleted">
                  <p>
                    <strong>Associated Items {{ slotProps.data.type === 'delete' ? 'Deleted' : 'Restored' }} </strong>
                  </p>
                  <ul class="list">
                    <li v-for="(ids, name) in slotProps.data.context.idsDeleted" :key="name">
                      <div class="mt-2">
                        <strong>{{ handleDetailsTitle(ids, name) }} </strong>
                      </div>
                      <div v-for="id in ids" :key="id">
                        {{ id }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <ul class="list-unstyled">
                    <li v-for="key in Object.keys(slotProps.data.context).filter(k => k !== 'errors')" :key="key">
                      <strong>{{ key }}:</strong> {{ slotProps.data.context[key] }}
                    </li>
                  </ul>

                  <ul v-if="slotProps.data.context.errors && slotProps.data.context.errors.length > 0" style="width: 600px" class="list-unstyled">
                    <li v-for="(error, index) in slotProps.data.context.errors" :key="index" class="border-bottom py-2">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataTable from '@/components/DataTable';

export default {
  name: 'AdminLog',
  components: {
    DataTable
  },
  async mounted() {
    await this.getUsers();
  },
  beforeDestroy() {
    this.clearStore();
  },
  computed: {
    ...mapGetters({
      loading: 'admin/loading',
      error: 'admin/error',
      allLogs: 'admin/allLogs',
      users: 'admin/users'
    }),
    columns() {
      return [
        { name: 'Created At', code: 'createdAt' },
        { name: 'Name', code: 'context.name' },
        { name: 'Item Type', code: 'context.type' },
        { name: 'Message', code: 'message' },
        {
          name: 'Type',
          code: 'type',
          search: true,
          searchType: 'dropdown',
          searchOptions: [
            { label: 'Billing', value: 'billing' },
            { label: 'Confirmation', value: 'confirmation' },
            { label: 'Deletion', value: 'delete' },
            { label: 'Import', value: 'import' },
            { label: 'Import Rollback', value: 'import-rollback' },
            { label: 'Report', value: 'report' },
            { label: 'Restored', value: 'restore' },
            { label: 'Scraper', value: 'scraper' },
            { label: 'Upload', value: 'upload' }
          ]
        },
        { name: 'Data', code: 'context' },
        { name: 'User', code: 'userSub' }
      ];
    }
  },
  methods: {
    ...mapActions({
      getAllLogs: 'admin/getAllLogs',
      getUsers: 'admin/getUsers'
    }),
    ...mapMutations({
      clearStore: 'admin/CLEAR_STORE'
    }),
    handleType(type) {
      if (type === 'restore') {
        return 'Restored';
      } else if (type === 'delete') {
        return 'Deletion';
      }
      return type;
    },
    handleTypeClass(type) {
      if (type === 'delete') {
        return {
          'badge-danger': true
        };
      }
    },
    handleDetailsTitle(name, value) {
      if (name.length !== 0) {
        return `${value} Ids:`;
      }
    }
  }
};
</script>
